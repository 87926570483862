import { keyframes } from '@emotion/react'
import { createStyles } from 'utils/css'

import { IPlaceholder } from './Placeholder.types'

export const root = ({
  animate,
  borderRadius,
  color,
  height,
  margin,
  width
}: IPlaceholder) =>
  createStyles(({ colors, radii, size, spacing }) => ({
    backgroundColor: colors[color] || color || colors.grey15,
    width: !width ? '100%' : size(width),
    height: size(height || 4.8),
    borderRadius: radii[borderRadius] || radii.lg,
    margin: margin && spacing(margin),
    animation: animate && `${fadeIn} 750ms ease-in-out infinite alternate`
  }))

const fadeIn = keyframes`
  from {
    opacity: 0.5;
    transform: scale(0.99)
  }
  to {
    opacity: 1;
    transform: scale(1)
  }
`
