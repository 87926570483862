import {
  Configuration,
  FrontendApi,
  UiNode,
  VerificationFlow,
  RecoveryFlow,
  UpdateRegistrationFlowBody,
  RegistrationFlow,
  ContinueWithVerificationUi,
  UpdateLoginFlowBody,
  LoginFlow,
  UpdateVerificationFlowWithCodeMethod,
  IdentityApi,
  OAuth2Api
} from '@ory/client'
import { isUiNodeInputAttributes } from '@ory/integrations/ui'
import type { NextRouter } from 'next/router'
import toast from 'react-hot-toast'
import { handleSegmentTracking } from 'utils/analytics'

import { handleFlowError, handleGetFlowError } from './errors'
import {
  FormProps,
  FormValues,
  Flow,
  UiTextWithDuplicateIdentifier
} from './types'

export { handleFlowError, handleGetFlowError }

const ory = new FrontendApi(
  new Configuration({
    basePath: process.env.NEXT_PUBLIC_KRATOS_URL,
    baseOptions: {
      withCredentials: true
    }
  })
)

export default ory

export const oauth2Api = new OAuth2Api(
  new Configuration({
    basePath: process.env.HYDRA_ADMIN_URL,
    baseOptions: {
      headers: {
        Authorization: `Basic ${Buffer.from(
          process.env.HYDRA_ADMIN_USERNAME +
            ':' +
            process.env.HYDRA_ADMIN_PASSWORD
        ).toString('base64')}`
      }
    }
  })
)

export const identityApi = new IdentityApi(
  new Configuration({
    basePath: process.env.KRATOS_ADMIN_URL,
    baseOptions: {
      headers: {
        Authorization: `Basic ${Buffer.from(
          process.env.KRATOS_ADMIN_USERNAME +
            ':' +
            process.env.KRATOS_ADMIN_PASSWORD
        ).toString('base64')}`
      }
    }
  })
)

export const emptyState = <T>() => ({}) as T

export const initializeNodes = <T extends FormValues>(
  nodes: Array<UiNode> = []
): T =>
  nodes.reduce((values, node) => {
    if (isUiNodeInputAttributes(node.attributes)) {
      if (
        node.attributes.type === 'button' ||
        node.attributes.type === 'submit'
      ) {
        return values
      }
      return {
        ...values,
        [node.attributes.name as keyof FormValues]: node.attributes.value
      }
    }
    return values
  }, emptyState<T>())

export const filterNodes = <T extends FormValues>(
  props: FormProps<T>
): Array<UiNode> => {
  const { flow, only } = props
  if (!flow) {
    return []
  }
  return flow.ui.nodes.filter(
    node => !only || node?.group === 'default' || node?.group === only
  )
}

export const findInputNodeByName = (nodes: UiNode[], name) => {
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    if (
      isUiNodeInputAttributes(node.attributes) &&
      node.attributes.name === name
    ) {
      return node
    }
  }
  return null
}

export const reorderFlowUI = (flow: Flow, ordering: string[]): Flow => {
  const nodes = flow?.ui?.nodes || []
  const reorderedNodes = ordering.reduce((acc, nodeName) => {
    const node = nodes.find(
      node =>
        isUiNodeInputAttributes(node.attributes) &&
        node.attributes.name === nodeName
    )
    if (node) {
      return [...acc, node]
    }
    return acc
  }, [])
  return {
    ...flow,
    ui: {
      ...flow?.ui,
      nodes: reorderedNodes
    }
  }
}

export const deserializeFlows = <T extends Flow>(flow: T): T => {
  // remove unnecessary messages
  const messages =
    flow?.ui?.messages?.filter(
      message =>
        message.id !== 1080003 &&
        message.id !== 1060003 &&
        message.id !== 1010003
    ) || []

  return {
    ...flow,
    ui: {
      ...flow.ui,
      messages
    }
  }
}

export const formatVerificationContent = (
  flow: VerificationFlow | RecoveryFlow
) => {
  switch (flow?.state) {
    case 'sent_email':
      return {
        headline: 'Check your email',
        message:
          'Please enter the 6-digit code from the email we just sent you.',
        footer:
          'If you did not get a code: you did not create an account or the email address is wrong.'
      }
    case 'passed_challenge':
      return {
        headline: 'Success',
        message: 'You successfully verified your account.',
        footer: null
      }
    case 'choose_method':
    default:
      return {
        headline: 'Verify your account',
        message: 'Please enter your email to verify your account.',
        footer: null
      }
  }
}

export const getCsrfToken = (flow: Flow): string => {
  const node = findInputNodeByName(flow.ui.nodes, 'csrf_token')
  if (node && isUiNodeInputAttributes(node.attributes)) {
    return node.attributes.value
  }
  return null
}

export const getEmailIdentifier = (flow: Flow): string => {
  const node =
    flow?.ui?.nodes && findInputNodeByName(flow?.ui?.nodes, 'identifier')
  if (node && isUiNodeInputAttributes(node.attributes)) {
    return node.attributes.value
  }
  return null
}

// This function is used to get the email identifier when the user sees the "Sign in and link" form, which is showed when trying to login with SSO on an existing account
export const getIdentifierFromFlowContext = (flow: Flow): string | null => {
  if (!flow) return null

  const context = flow.ui?.messages?.find(
    m => (m.context as UiTextWithDuplicateIdentifier)?.duplicateIdentifier
  )?.context
  const duplicateIdentifier = (context as UiTextWithDuplicateIdentifier)
    ?.duplicateIdentifier

  return duplicateIdentifier ?? getEmailIdentifier(flow)
}

export const registrationSubmit =
  (
    flow: RegistrationFlow,
    router: NextRouter,
    handleFlowUpdate: (data: RegistrationFlow) => void
  ) =>
  async (values: UpdateRegistrationFlowBody) => {
    try {
      let sanitizedValues = { ...values }
      if (values.method === 'code') {
        const code = (values as UpdateVerificationFlowWithCodeMethod).code
        const state = (flow as VerificationFlow).state
        const isNotResendCode = !(values as any).resend

        if (
          (!code || code.length < 6) &&
          state === 'sent_email' &&
          isNotResendCode
        ) {
          toast.error('Please enter the 6-digit code')
          return
        }

        //eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { password, password_confirm, ...rest } =
          values as UpdateRegistrationFlowBody & {
            password: string
            password_confirm: string
          }
        sanitizedValues = rest
      }

      const { data } = await ory.updateRegistrationFlow({
        flow: String(flow?.id),
        updateRegistrationFlowBody: sanitizedValues
      })
      handleSegmentTracking({
        message: 'Registration Success',
        data
      })
      if (flow?.return_to) {
        window.location.href = flow?.return_to
        return
      }
      if (data.continue_with) {
        for (const item of data.continue_with as ContinueWithVerificationUi[]) {
          switch (item.action) {
            case 'show_verification_ui':
              await router.push(
                `${item.flow.url}&verifiable_address=${item.flow.verifiable_address}&return_to=${flow.return_to}`
              )
              return
          }
        }
      }
      if (
        values.method === 'code' &&
        (values as unknown as { code: string }).code
      )
        router.push('/api/verification')
    } catch (err) {
      handleFlowError(router, 'registration', handleFlowUpdate)(err)
    }
  }

export const createOrGetRegistrationFlow = async (
  router: NextRouter,
  handleFlowUpdate: (data: RegistrationFlow) => void
) => {
  const {
    flow: flowId,
    return_to: returnTo,
    after_verification_return_to: afterVerificationReturnTo
  } = router.query

  // If we have a flow ID, we fetch it
  if (flowId) {
    ory
      .getRegistrationFlow({ id: String(flowId) })
      .then(({ data }) => {
        handleFlowUpdate(data)
      })
      .catch(err =>
        handleFlowError(router, 'registration', handleFlowUpdate)(err)
      )
    return
  }

  // Otherwise we initialize new flow
  ory
    .createBrowserRegistrationFlow({
      returnTo: returnTo ? String(returnTo) : undefined,
      afterVerificationReturnTo: afterVerificationReturnTo
        ? String(afterVerificationReturnTo)
        : undefined
    })
    .then(({ data }) => handleFlowUpdate(data))
    .catch(err =>
      handleFlowError(router, 'registration', handleFlowUpdate)(err)
    )
}

export const loginSubmit =
  (
    flow: Flow,
    router: NextRouter,
    handleFlowUpdate: (data: LoginFlow) => void
  ) =>
  async (values: UpdateLoginFlowBody) => {
    try {
      let sanitizedValues = { ...values }
      if (values.method === 'code') {
        const code = (values as UpdateVerificationFlowWithCodeMethod).code
        const state = (flow as VerificationFlow).state
        const isNotResendCode = !(values as any).resend

        if (
          (!code || code.length < 6) &&
          state === 'sent_email' &&
          isNotResendCode
        ) {
          toast.error('Please enter the 6-digit code')
          return
        }

        //eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { password, ...rest } = values as UpdateLoginFlowBody & {
          password: string
        }
        sanitizedValues = rest
      }

      const { data } = await ory.updateLoginFlow({
        flow: String(flow?.id),
        updateLoginFlowBody: sanitizedValues
      })
      handleSegmentTracking({
        message: 'Login Success',
        data
      })
      router.push('/api/verification')
    } catch (err) {
      handleFlowError(router, 'login', handleFlowUpdate)(err)
    }
  }
