import { PartnerWhitelabelFragment } from 'graph/generated/payments/graphql-types'
import { AlternativePaymentsLogo } from 'lib/ui/Logo'
import Image from 'next/image'
import { createStyles } from 'utils/css'
import { breakpoints } from 'utils/theme/mediaQuery'

export enum Layout {
  DEFAULT = 'default',
  DASHBOARD = 'dashboard'
}

interface IFormatLogo {
  layout: Layout
  height: number
  width: number
}
const formatLogo = ({ layout, height, width }: IFormatLogo) =>
  createStyles(({ mediaQuery, size, spacing }) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    margin: layout === Layout.DEFAULT ? 0 : 'auto',
    maxWidth:
      layout === Layout.DEFAULT ? `${size(width)} !important` : size(18.5),
    [mediaQuery('xs')]: {
      maxWidth: size(20)
    },
    [mediaQuery('sm')]: {
      maxWidth: size(width)
    },
    minHeight: size(height),
    img: {
      objectFit: 'contain',
      objectPosition: layout === Layout.DEFAULT ? 'left center' : 'center',
      padding: layout === Layout.DEFAULT ? 0 : spacing(1),
      [mediaQuery('lg')]: {
        padding: 0
      }
    }
  }))

interface IRenderLogo extends IFormatLogo {
  partnerName: string
  logo: string
}
const RenderLogo = ({
  layout,
  height,
  width,
  partnerName,
  logo
}: IRenderLogo) =>
  logo ? (
    <div
      css={formatLogo({
        layout,
        height,
        width
      })}
    >
      <Image
        fill
        priority
        src={logo}
        alt={`${partnerName} logo`}
        sizes={
          layout === Layout.DASHBOARD
            ? `185px, (min-width: ${breakpoints.xs}) 200px, (min-width: ${breakpoints.sm}) 250px)`
            : '250px'
        }
      />
    </div>
  ) : (
    <AlternativePaymentsLogo color='#181a1b' />
  )

interface ILogo {
  layout: Layout
  height?: number
  width?: number
  partner: PartnerWhitelabelFragment
}
const Logo = ({ layout, height = 7.5, width = 25, partner }: ILogo) => {
  const partnerName = partner?.name
  const logo = partner?.paymentsPortalCustomization?.logoUrl

  return (
    <>
      {partnerName ? (
        <RenderLogo
          layout={layout}
          height={height}
          width={width}
          partnerName={partnerName}
          logo={logo}
        />
      ) : (
        <AlternativePaymentsLogo />
      )}
    </>
  )
}
export default Logo
