import { createStyles } from 'utils/css'
import theme from 'utils/theme'

import {
  IInput,
  IInputLabelStyles,
  IInputSize,
  IInputWarningStyles
} from './Input.types'
const { colors, size, spacing } = theme

const setStyles = (inputSize?: IInputSize) => {
  switch (inputSize) {
    case 'sm':
      return {
        left: spacing(0.25),
        height: size(1.6)
      }
    case 'md':
      return {
        left: spacing(0.5),
        height: size(2.0)
      }
    case 'xl':
      return {
        left: spacing(1),
        height: size(3.4)
      }
    default:
      return {
        left: spacing(1),
        height: size(2.8)
      }
  }
}
export const beforeStyles = (inputSize?: IInputSize) => ({
  color: colors.surface400,
  position: 'absolute',
  bottom: size(1),
  width: size(1.2),
  zIndex: 1,
  fontWeight: 600,
  ...setStyles(inputSize)
})

export const currencyStyles = (inputSize?: IInputSize) => ({
  position: 'relative',
  '&:before': {
    content: '"$"',
    ...beforeStyles(inputSize)
  }
})

export const rootStyles = ({
  currency,
  inputSize,
  styles
}: Pick<IInput, 'currency' | 'inputSize' | 'styles'>) => {
  const defaultStyles = {
    textAlign: 'left',
    color: colors.grey30
  }

  const currencyStyle = currency ? { ...currencyStyles(inputSize) } : {}

  return { ...defaultStyles, ...currencyStyle, ...styles?.root }
}

export const requiredLabel = () =>
  createStyles(() => ({
    '&:after': {
      content: '"*"'
    }
  }))

export const labelStyles = ({
  error,
  foreground,
  hideLabel,
  inputSize,
  labelProps,
  styles
}: IInputLabelStyles) =>
  createStyles(({ colors, effects, inputs: { labels } }) => {
    const labelTreatment = hideLabel
      ? { ...effects.hide }
      : {
          fontSize: labels[inputSize],
          marginBottom: '0.25rem',
          ...labelProps
        }
    const defaultLabel = {
      colors: error ? colors.danger : colors[foreground],
      ...labelTreatment
    }
    return { ...defaultLabel, ...styles?.label }
  })

export const warningStyles = ({ inputSize, type }: IInputWarningStyles) => {
  const smallSize = inputSize === 'sm' || inputSize === 'md'
  return createStyles(({ colors, spacing }) => ({
    fill: colors.danger,
    background: colors.light,
    position: 'absolute',
    right: type === 'date' ? spacing(2.5) : spacing(0.25),
    top: spacing(inputSize === 'xl' ? 1 : 0.5),
    width: smallSize && spacing(1),
    height: smallSize && spacing(1)
  }))
}

export const fieldStyles = ({
  currency,
  error,
  foreground,
  inputSize,
  styles,
  type
}: IInput) =>
  createStyles(
    ({
      colors,
      effects,
      inputs: { fonts, radii, sizes },
      spacing,
      treatments
    }) => {
      const setCurrencyPadding = (vertical: number) => {
        switch (inputSize) {
          case 'sm':
          case 'md':
            return spacing([vertical, 1.5])
          default:
            return spacing([vertical, 2.25])
        }
      }

      const setPadding = (vertical: number) => {
        switch (type) {
          case 'select':
            return spacing([vertical, 1 + 0.5, vertical, 1])
          default:
            return currency
              ? setCurrencyPadding(vertical)
              : spacing([vertical, 1])
        }
      }

      const handleOverflow = type !== 'textarea' ? effects.ellipsis : {}

      const defaultField = {
        color: error ? colors.danger : colors[foreground],
        background: colors.light,
        border: `1px solid ${error ? colors.danger : colors.grey15}`,
        borderRadius: radii.xxl,
        textAlign: 'left',
        height:
          type === 'textarea'
            ? `calc(${sizes[inputSize]} * 2)`
            : sizes[inputSize],
        lineHeight: type !== 'textarea' && sizes[inputSize],
        fontSize: fonts[inputSize],
        padding:
          type === 'select' && inputSize === 'sm'
            ? setPadding(0)
            : setPadding(0.25),
        width: '100%',
        ...handleOverflow,
        ...treatments.focus,
        '&:focus': {
          borderColor: error && colors.danger
        },
        '&:focus-visible': {
          outlineColor: error && colors.danger
        },
        '&::placeholder': {
          ...treatments.placeholder
        },
        '&:disabled': {
          ...treatments.disabled,
          background: colors.grey15
        }
      }

      const selectStyles =
        type === 'select'
          ? {
              color: colors[foreground],
              appearance: 'none',
              backgroundImage: `linear-gradient(45deg, transparent 50%, ${colors.grey} 50%),linear-gradient(135deg, ${colors.grey}  50%, transparent 50%)`,
              backgroundPosition: 'calc(100% - 1.2rem), calc(100% - 0.6rem)',
              backgroundSize: '0.6rem 0.6rem, 0.6rem 0.6rem',
              backgroundRepeat: 'no-repeat',
              lineHeight: inputSize === 'lg' ? 'inherit' : 1.2,
              '&:disabled': {
                ...treatments.disabled,
                background: colors.grey15
              }
            }
          : {}

      return { ...defaultField, ...selectStyles, ...styles?.field }
    }
  )

export const numberField = {
  root: {
    position: 'relative',
    '&:before': {
      content: '"#"',
      color: colors.grey30,
      position: 'absolute',
      left: spacing(1),
      bottom: size(1),
      width: size(1.2),
      height: size(2.7),
      zIndex: 1,
      fontWeight: 600
    }
  },
  field: {
    paddingLeft: spacing(2)
  }
}
